import { BASE_COLORS, BASE_FONTS } from "../elements";

export const BASE_TEXT_VARIANTS = {
  defaults: {
    fontSize: 14,
    fontFamily: BASE_FONTS.regular,
    color: "text",
  },
  h3: {
    fontSize: 30,
    fontWeight: 700,
    fontFamily: BASE_FONTS.bold,
    color: "text",
  },
  h4: {
    fontSize: 24,
    fontWeight: 700,
    fontFamily: BASE_FONTS.bold,
    color: "text",
  },
  buttonLabel: {
    fontSize: 20,
    fontFamily: BASE_FONTS.semiBold,
    color: "text",
  },
  buttonWhiteLabel: {
    fontSize: 20,
    fontFamily: BASE_FONTS.semiBold,
    color: "white",
  },
  buttonPrimaryLabel: {
    fontSize: 20,
    fontFamily: BASE_FONTS.semiBold,
    color: "primary",
  },
  p1: {
    fontSize: 20,
    fontFamily: BASE_FONTS.regular,
    color: "text",
  },
  p1_white: {
    fontSize: 20,
    fontFamily: BASE_FONTS.regular,
    color: BASE_COLORS.white,
  },
  p2: {
    fontSize: 18,
    fontFamily: BASE_FONTS.regular,
    color: "text",
  },
  p3: {
    fontSize: 16,
    fontFamily: BASE_FONTS.regular,
    color: "text",
  },
  p4: {
    fontSize: 14,
    fontFamily: BASE_FONTS.regular,
    color: "text",
  },
  p4_white: {
    fontSize: 14,
    color: "white",
    fontFamily: BASE_FONTS.regular,
  },
  p5: {
    fontSize: 12,
    fontFamily: BASE_FONTS.regular,
    color: "text",
  },
  p6: {
    fontSize: 10,
    fontFamily: BASE_FONTS.regular,
    color: "text",
  },
  p1_bold: {
    fontSize: 20,
    fontFamily: BASE_FONTS.bold,
    color: "text",
  },
  p1_bold_white: {
    fontSize: 20,
    fontFamily: BASE_FONTS.bold,
    color: "white",
  },
  p1_medium: {
    fontSize: 20,
    fontFamily: BASE_FONTS.semiBold,
    color: "text",
  },
  p3_medium: {
    fontSize: 16,
    fontFamily: BASE_FONTS.semiBold,
    color: "text",
  },
  p4_bold: {
    fontSize: 16,
    fontFamily: BASE_FONTS.bold,
    color: "text",
  },
};

export type AppTextVariants = keyof typeof BASE_TEXT_VARIANTS;
