// "use client";
// import { BASE_COLORS, ThemedColorProps, ThemedSpaceProps } from "@/theme";
// import { StyledWithConfig } from "@/utils/styled";
// import styled from "styled-components";
// import {
//   background,
//   layout,
//   space,
//   border,
//   BackgroundProps,
//   BorderProps,
//   LayoutProps,
//   color,
//   width,
//   WidthProps,
// } from "styled-system";

// type InputBoxProps = BackgroundProps &
//   BorderProps &
//   LayoutProps &
//   ThemedSpaceProps &
//   ThemedColorProps &
//   WidthProps & {
//     placeholderColor?: string;
//     boxShadow?: string;
//   };

// export const StyledInputBox = styled.input.withConfig(
//   StyledWithConfig
// )<InputBoxProps>`
//   ${width}
//   height: 40px;
//   outline: none;
//   font-family: DMSansRegular;
//   font-size: 16px;
//   padding: 10px;
//   ${border}
//   ${background}
//   ${layout}
//   ${space}
//   ${color}
//   &::placeholder {
//     color: ${(props) => props.placeholderColor || BASE_COLORS.lightGrey};
//     font-family: DMSansRegular;
//     font-size: 15px;
//   }
//   &::-webkit-file-upload-button {
//     background-color: ${BASE_COLORS.primary};
//     color: ${BASE_COLORS.white};
//     cursor: pointer;
//     height: 100%;
//     border: none;
//     padding-left: 16px;
//     padding-right: 16px;
//   }

//   &::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     appearance: none;
//     width: 25px;
//     height: 25px;
//     background: ${BASE_COLORS.primary};
//     cursor: pointer;
//     border-radius: 50%;
//   }

//   &::-webkit-slider-runnable-track {
//     width: 100%;
//     height: 8px;
//     background: ${BASE_COLORS.lightGrey};
//   }

//   &::-moz-range-thumb {
//     width: 25px;
//     height: 25px;
//     background: ${BASE_COLORS.primary};
//     cursor: pointer;
//     border-radius: 50%;
//   }

//   &::-moz-range-track {
//     width: 100%;
//     height: 8px;
//     background: ${BASE_COLORS.lightGrey};
//   }

//   &::-ms-thumb {
//     width: 25px;
//     height: 25px;
//     background: ${BASE_COLORS.primary};
//     cursor: pointer;
//     border-radius: 50%;
//   }

//   &::-ms-track {
//     width: 100%;
//     height: 8px;
//     background: ${BASE_COLORS.lightGrey};
//     border-color: transparent;
//     color: transparent;
//   }
// `;

"use client";

import { BASE_COLORS, ThemedColorProps, ThemedSpaceProps } from "@/theme";
import { StyledWithConfig } from "@/utils/styled";
import styled from "styled-components";
import {
  background,
  layout,
  space,
  border,
  BackgroundProps,
  BorderProps,
  LayoutProps,
  color,
  width,
  WidthProps,
} from "styled-system";

type InputBoxProps = BackgroundProps &
  BorderProps &
  LayoutProps &
  ThemedSpaceProps &
  ThemedColorProps &
  WidthProps & {
    placeholderColor?: string;
    boxShadow?: string;
  };

export const StyledInputBox = styled.input.withConfig(
  StyledWithConfig
)<InputBoxProps>`
  ${width}
  height: 40px;
  outline: none;
  font-family: DMSansRegular;
  background-color: ${(props) => props.theme.colors.background};
  font-size: 16px;
  padding: 10px;
  color: white;
  color: ${(props) => props.theme.colors.foreground};
  ${border}
  ${background}
  ${layout}
  ${space}
  ${color}

  &::placeholder {
    color: ${(props) => props.placeholderColor || BASE_COLORS.lightGrey};
    font-family: DMSansRegular;
    font-size: 15px;
  }

  &::-webkit-file-upload-button {
    background-color: ${BASE_COLORS.primary};
    color: ${BASE_COLORS.white};
    cursor: pointer;
    height: 100%;
    border: none;
    padding-left: 16px;
    padding-right: 16px;
  }

  /* Range input styles */
  &[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    background: transparent;
    padding: 0;
    margin: 0;
  }

  &[type="range"]:focus {
    outline: none;
  }

  /* Webkit (Chrome, Safari, newer versions of Opera) */
  &[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: ${BASE_COLORS.primaryLight};
    border-radius: 4px;
    border: none;
  }

  &[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: ${BASE_COLORS.primary};
    cursor: pointer;
    border-radius: 50%;
    margin-top: -6px; /* (thumb height - track height) / 2 */
    border: none;
  }

  /* Mozilla Firefox */
  &[type="range"]::-moz-range-track {
    width: 100%;
    height: 8px;
    background: ${BASE_COLORS.lightGrey};
    border-radius: 4px;
    border: none;
  }

  &[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: ${BASE_COLORS.primary};
    cursor: pointer;
    border-radius: 50%;
    border: none;
  }

  /* Microsoft Edge and IE */
  &[type="range"]::-ms-track {
    width: 100%;
    height: 8px;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &[type="range"]::-ms-fill-lower,
  &[type="range"]::-ms-fill-upper {
    background: ${BASE_COLORS.lightGrey};
    border-radius: 4px;
    border: none;
  }

  &[type="range"]::-ms-thumb {
    width: 20px;
    height: 20px;
    background: ${BASE_COLORS.primary};
    cursor: pointer;
    border-radius: 50%;
    margin-top: 0;
    border: none;
  }

  && {
    &[type="range"],
    &[type="range"]:focus,
    &[type="range"]:active,
    &[type="range"]:hover {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
`;
