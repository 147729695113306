import { backgroundColor } from "styled-system";
import {
  BASE_COLORS,
  BASE_BORDER_RADII,
  BASE_FONTS,
  BASE_SPACING,
} from "../elements";

export const BASE_BUTTON_VARIANTS = {
  primary: {
    backgroundColor: BASE_COLORS.primary,
    color: "white",
    border: "none",
    borderRadius: BASE_BORDER_RADII.md,
    padding: "5px",
  },
  altPrimary: {
    backgroundColor: BASE_COLORS.white,
    color: BASE_COLORS.primary,
    border: "none",
    borderRadius: BASE_BORDER_RADII.xs,
    padding: "10px",
  },
  outline: {
    backgroundColor: "white",
    borderColor: BASE_COLORS.primary,
    color: BASE_COLORS.primary,
    borderRadius: 1,
    padding: "3px",
  },
  disabled: {
    backgroundColor: "#efefef",
    border: "none",
    color: "black",
    borderRadius: BASE_BORDER_RADII.md,
    padding: "5px",
  },
  bgOnly: {
    backgroundColor: BASE_COLORS.primary,
    borderRadius: BASE_BORDER_RADII.md,
  },
};
export type AppButtonVariants = keyof typeof BASE_BUTTON_VARIANTS;
