"use client";
import styled, { css } from "styled-components";
import {
  space,
  borderRadius,
  layout,
  LayoutProps,
  typography,
  TypographyProps,
  color,
  background,
  BackgroundProps,
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  position,
  PositionProps,
  style,
  compose,
  shadow,
  ShadowProps,
  BackgroundColorProps,
} from "styled-system";
import { StyledWithConfig } from "@/utils/styled";
import {
  BASE_COLORS,
  ThemedBorderRadiusProps,
  ThemedColorProps,
  ThemedSpaceProps,
} from "@/theme";

const gap = style({
  prop: "gap",
  key: "space",
  scale: "space",
});

export const systemStyles = compose(
  space,
  layout,
  typography,
  color,
  background,
  border,
  flexbox,
  position,
  borderRadius,
  gap,
  shadow,
  grid
);

export type BoxProps = ThemedSpaceProps &
  ThemedBorderRadiusProps &
  LayoutProps &
  TypographyProps &
  ThemedColorProps &
  BackgroundProps &
  BorderProps &
  FlexboxProps &
  PositionProps &
  BackgroundColorProps &
  ShadowProps &
  GridProps & {
    gap?: string | number | string[] | number[];
    cursor?: "pointer";
  };

export const Box = styled.div.withConfig(StyledWithConfig)<BoxProps>`
  display: ${(props) => props.display || "flex"};
  flex-direction: ${(props) => props.flexDirection || "column"};
  box-sizing: border-box;
  ${(props) =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${systemStyles}
`;

export const CenterBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  ${flexbox}
`;

export const HoverBox = styled(CenterBox)<BoxProps>`
  &:hover {
    background-color: ${BASE_COLORS.lightGrey};
  }
  cursor: pointer;
`;

export const GreyHoverBox = styled(CenterBox)<BoxProps>`
  &:hover {
    background-color: ${BASE_COLORS.grey};
  }
  cursor: pointer;
`;
export const GreyHoverBoxLight = styled(CenterBox)<BoxProps>`
  &:hover {
    background-color: ${BASE_COLORS.lightGreyTransparent};
  }
  cursor: pointer;
`;
interface PrimaryHoverBoxProps extends BoxProps {
  hoverBackgroundColor?: string;
}

export const PrimaryHoverBox = styled(Box)<PrimaryHoverBoxProps>`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${(props) =>
      props.hoverBackgroundColor || BASE_COLORS.primaryTransparent};
  }
`;
export const HoverBoxFooter = styled(CenterBox)<BoxProps>`
  flex-direction: row;
  align-items: center;
  width: fit-content;
  cursor: pointer;
`;

export const ImageContainer = styled(Box)<BoxProps>`
  transition: background-color 0.3s ease-in-out;
  width: 35px;
  height: 35px;
  border-radius: 20px;

  &:hover {
    background-color: ${BASE_COLORS.primaryTransparent};
  }
`;

export const HoverText = styled(Box)<BoxProps>`
  transition: font-weight 0.3s ease-in-out;

  ${HoverBoxFooter}:hover & {
    font-weight: bold;
  }
`;
export const PrimaryLightHoverBox = styled(Box)<BoxProps>`
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${BASE_COLORS.primaryTransparentLight};
  }
`;

export const PrimaryHoverBox2 = styled(CenterBox)<BoxProps>`
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${BASE_COLORS.primary};
    color: ${BASE_COLORS.white};
  }
`;

export const SpecialBox = styled(CenterBox)<BoxProps>`
  cursor: pointer;
  transition: all 0.8s ease-in-out, transform 0.8s ease-in-out;
`;
export const SpecialBox2 = styled(Box)<{ visible: boolean; width: string }>`
  width: ${({ width }) => width}%;
  background-color: ${BASE_COLORS.primary};
  transition: all 0.5s ease-in-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) =>
    visible ? "translateX(0)" : "translateX(-50px)"};
`;
export const OptionsContainer = styled(Box)<{ open: boolean }>`
  opacity: ${({ open }) => (open ? "1" : "0")};
  transition: all 0.1s ease-in-out;
`;

export const ShadowBox = styled(Box)<BoxProps>`
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.18);
`;

export const PrimaryColorBox = styled(Box)<BoxProps>`
  &:hover {
    color: ${BASE_COLORS.primaryLight};
  }
`;

export const StyledBlinkBox = styled(Box)`
  animation: blink 3s infinite;

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
